/**
 * Video Player for displaying live and historical video streams
 * via the custom Hellometer video streaming solution.
 */
import { useRef, useEffect, useState } from 'react';
import videojs from 'video.js';
import type Player from 'video.js/dist/types/player';
import 'video.js/dist/video-js.css';
import { useCamera } from '../../hooks/useCamera';
import {
  getHistoricalManifestUrl,
  getLiveManifestUrl,
  getStreamTimes,
} from '../../utils/video';
import type { Location } from '../../types/Location';
import VideoPlayer from './VideoPlayer';

export default function VideoPlayerCustom({
  location,
  cameraId,
}: {
  location: Location;
  cameraId: string;
}): JSX.Element {
  const [player, setPlayer] = useState<Player | null>(null);
  const [manifestUrl, setManifestUrl] = useState<string | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { isLive, playbackSpeed, videoDateTime, isPlaying, setIsPlaying } =
    useCamera();

  async function sleep(ms: number | undefined) {
    return await new Promise((resolve) => setTimeout(resolve, ms));
  }
  // On load, initialize videojs
  useEffect(() => {
    console.log("Initializing");
    if (videoRef.current === null) {
      sleep(1000);
      return;
    }
    const playerObject = videojs(videoRef.current, {
      autoplay: true,
      muted: true,
      playsinline: true,
      controls: true,
      preload: 'auto',
      userActions: {
        click: false,
      },
      html5: {
        vhs: {
          overrideNative: true,
        },
      },
      // Disable fullscreen on iOS
      disablePictureInPicture: true,
      nativeControlsForTouch: false,
    });
    setPlayer(playerObject);

    // Add listeners.
    let retryCount = 0;
    const maxRetries = 500; // Set a limit for retries

    playerObject.on('error', () => {
      if (retryCount >= maxRetries) {
        console.error('Max retries reached. Stopping retry attempts.');
        return;
      }

      retryCount++;
      console.log(`Retrying to load the video... Attempt ${retryCount}`);

      setTimeout(() => {
        playerObject.load();
        playerObject.play()?.catch((error) => {
          console.error('Error during retry:', error);
        });
      }, 1000); // Add a 2-second delay before retrying
    });

    playerObject.on('loadedmetadata', () => {
      const w = wrapperRef.current?.clientWidth;
      playerObject.width(w);
    });

    // Cleanup.
    return () => {
      if (player !== null) {
        player.dispose();
      }
    };
  }, [videoRef.current]);

  // When the manifest URL changes, update the player source.
  useEffect(() => {
    console.log("Update manifesturl, player. Set is playing.");
    if (player === null || manifestUrl === null) {
      sleep(1000);
      return;
    }
    player.src({
      src: manifestUrl,
      type: 'application/x-mpegURL',
    });
    setIsPlaying(true);
  }, [manifestUrl, player]);

  // Go to live or historical video.
  useEffect(() => {
    console.log("Go live or historical manifest url. Set manifest url");
    if (isLive) {
      getLiveManifestUrl(location.id, cameraId)
        .then((manifestUrl: string | null) => {
          setManifestUrl(manifestUrl);
        })
        .catch((error) => {
          console.error('Error getting live video', error);
        });
    } else {
      const { startTime, endTime } = getStreamTimes(
        videoDateTime,
        location.timezone
      );
      getHistoricalManifestUrl(location.id, cameraId, startTime, endTime)
        .then((manifestUrl: string | null) => {
          setManifestUrl(manifestUrl);
        })
        .catch((error) => {
          console.error('Error getting historical video', error);
        });
    }
  }, [player, isLive, videoDateTime, location, cameraId]);

  // Change video speed.
  useEffect(() => {
    console.log("Change video speed");
    if (player === null) {
      return;
    }
    try {
      player.playbackRate(playbackSpeed);
      player.defaultPlaybackRate(playbackSpeed);
    } catch (err) {
      console.log(err);
    }
  }, [playbackSpeed]);

  // Play or pause the video based on the isPlaying prop.
  useEffect(() => {
    console.log("isPlaying:", isPlaying);
    if (player === null) {
      return;
    }

    if (!isPlaying) {
      player.pause();
    } else {
      player.play()?.catch((err) => {
        console.log(err);
      });
    }
  }, [isPlaying]);

  // useEffect(() => {
  //   if (!isLive) {
  //     return;
  //   }
  //   // Refresh every 60 seconds.
  //   const interval = setInterval(() => {
  //     player?.src({
  //       src: manifestUrl,
  //       type: 'application/x-mpegURL',
  //     });

  //     console.log('Refreshing video.');
  //   }, 60000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  return (
    <VideoPlayer
      location={location}
      cameraId={cameraId}
      videoRef={videoRef}
      wrapperRef={wrapperRef}
    />
  );
}
