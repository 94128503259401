/**
 * Constants used throughout the application.
 */

// List of locations that use the custom video pipeline.
export const CUSTOM_VIDEO_LOCATIONS = ['24','47','72','73','98','100','102','125','128','157','204','205','209','218','225','233','235','241','242','249','250','257','262','266','283','368','371','372','382','395','404','405','406','407','408','413','414','415','416','417','418','420','421','423','424','426','427','428','429','431','432','434','435','436','437','438','439','440','442','443','447','448','449','450','451','453','454','455','457','458','459','460','461','462','465','466','467','468','469','470','472','474','475','477','482','484','485','486','487','488','490','491','492','494','496','508','509','527','529','530'];
export const AVAILABLE_FILTERS = ["24h","1h","30min","15min","5min"]
export const PANDAFRANCHISE = "59"
export const MONITORSTREAMSENABLED = ['6', '59']
// TODO:102,422                                                '259',                                                                                                                                                                                                                                                                                                                                                                                                                                                    
