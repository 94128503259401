
import {  StackData } from "@/types/Stack";
import { StackTableComponent } from "@/components/tables/StackTableComponent"
import { useData } from "@/hooks/useData";
import { Stack, columns } from "./StackColumn";

function setData(driveOffsData: StackData[]): Stack[]{
    const {
        selectedLocations,
      } = useData();
  // Fetch data from your API here.
  const result = driveOffsData
  .filter(data => selectedLocations.some(loc => loc.id === data.LocationID)&& data.StackSize > 0
  ).map(data => ({
    timeStamp: data.TimeStamp,
    location: selectedLocations.find(loc => loc.id === data.LocationID),
    stackSize: data.StackSize
  }));
  return result as Stack[]
}

export default function DriveOffTable({
  stackData,
}: {
  stackData: StackData[] | undefined;
}) {
    if (stackData){
        const data = setData(stackData)
        return (
            
            <StackTableComponent columns={columns} data={data} />
        )}
    return <></>
}
