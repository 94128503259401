/**
 * Frontend interface for interacting with the API.
 */
import { getUserToken } from './user';
import type { Location } from '../types/Location';
import type { Order } from '../types/Order';
import type { Detection } from '../types/Detection';
import type { Average } from '../types/Average';
import type { Rank } from '../types/Rank';
import type { DayPartData } from '../types/DayPartData';
import type { RegionsStats } from '../types/RegionsStats';
import type { Camera } from '../types/Camera';
import type { UserProfile } from '../types/UserProfile';
import type { DayPart } from '../types/DayPart';
import { dateToUtc } from './time';
import { Roles } from '../types/Roles';
import { FranchiseUser } from '../types/FranchiseUser';
import { FranchiseLocation } from '../types/FranchiseLocation';
import { FranchiseConfiguration } from '../types/FranchiseConfiguration';
import { Stack } from '@/types/Stack';





export async function getAllLabelmeImages(franchiseId: number, locationId: number): Promise<object> {
  const urls = await getLabelmeImages(franchiseId, locationId);
  const results = await processImageUrls(urls);
  
  return results;
}


export async function callMothership(machine_numbers: string, action: string): Promise<boolean> {
  const token = await getUserToken();
  console.log(machine_numbers, action)
  

  if (!token) {
    console.error('No token found.');
    return false;
  }

  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/mothership/run_playbook`,
      {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          machine_numbers: machine_numbers,
          action: action,
          
          
          
        }),
      }
    );
    console.log(response)

    if (!response.ok) {
      console.log('b')
      const errorText = await response.text(); // Read the error message
      console.error('Failed to post mothership command:', errorText);
      return false;
    }
    console.log('c')

    return true;
  } catch (error) {
    console.log('d')
    console.error('Error calling mothership:', error);
    return false;
  }
}









async function processImageUrls(imageMap: Map<string, string>): Promise<object> {
  const results = {};

  for (const [key, url] of imageMap.entries()) {
    try {
      const imageData = await fetchImageData(url);
      results[key] = imageData;
    } catch (error) {
      console.error(`Error processing URL ${url}:`, error);
    }
  }

  return results;
}


async function fetchImageData(url: string): Promise<string> {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Failed to fetch image data: ${response.status} ${response.statusText}`);
    }
    
    const blob = await response.blob();
    return await convertBlobToBase64(blob);
  } catch (error) {
    console.error(`Error fetching image data from ${url}:`, error);
    throw error;
  }
}

function convertBlobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}


export async function getLabelmeImages(franchiseId: number, locationId: number): Promise<Map<string, string>> {
  const token = await getUserToken();

  if (!token) {
    console.error('No token found.');
    return new Map();
  }

  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/franchises/${franchiseId}/locations/${locationId}/regions`,
      {
        method: 'GET',
        headers: {
          Authorization: token,
        },
      }
    );
    console.log("Response details:", response);

    if (!response.ok) {
      console.error('Failed to fetch images:', response.status, response.statusText);
      return new Map();
    }

    const data = await response.json();
    console.log("Parsed JSON data:", data);

    return new Map(Object.entries(data));
  } catch (error) {
    console.error('Error fetching images:', error);
    return new Map();
  }
}



// async function fetchImageData(url: string): Promise<any> {
//   console.log("fetching....................")
//   try {
//     const response = await fetch(url);
//     console.log('here is resp fid', response)
//     if (!response.ok) {
//       throw new Error(`Failed to fetch image data: ${response.status} ${response.statusText}`);
//     }
//     // Assuming the response is JSON. If it's another type, adjust accordingly.
//     return await response.json();
//   } catch (error) {
//     console.error(`Error fetching image data from ${url}:`, error);
//     throw error;
//   }
// }





export async function postLabelmeImages(franchiseId: number, locationId: number, jsImage: string, fileName: string): Promise<boolean> {
  const token = await getUserToken();
  console.log(franchiseId, locationId, jsImage, fileName)
  

  if (!token) {
    console.error('No token found.');
    return false;
  }

  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/franchises/${franchiseId}/locations/${locationId}/regions`,
      {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fileType: 'application/json',
          fileKey: fileName,
          fileContent: jsImage,
          
        }),
      }
    );
    console.log(response)

    if (!response.ok) {
      console.log('b')
      const errorText = await response.text(); // Read the error message
      console.error('Failed to post images:', errorText);
      return false;
    }
    console.log('c')

    return true;
  } catch (error) {
    console.log('d')
    console.error('Error fetching images:', error);
    return false;
  }
}


/**
 *
 * @returns The user profile.
 */
export async function getUserProfile(): Promise<UserProfile> {
  const token = await getUserToken();

  if (token === null) {
    console.log('No token found.');
    return {
      locations: [],
      franchiseRoles: new Map<string, Roles>(),
      notificationLocations: [],
      franchises: []
    };
  }

  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/v2/user/profile`,
      {
        method: 'GET',
        headers: {
          Authorization: token,
        },
      }
    );

    if (!response.ok) {
      return {
        locations: [],
        franchiseRoles: new Map<string, Roles>(),
        notificationLocations: [],
        franchises: []
      };
    }

    let data = await response.json();
    data["franchiseRoles"] = new Map(Object.entries(data["franchiseRoles"]));
    return data as UserProfile;
  } catch (error) {
    console.error(error);
  }

  return {
    locations: [],
    franchiseRoles: new Map<string, Roles>(),
    notificationLocations: [],
    franchises: []
  };
}

/**
 * @description Get the orders for locations on a given day.
 */
export async function getLocationOrders(
  franchiseId: string,
  date: Date,
  locations: Location[]
): Promise<Order[]> {
  const token = await getUserToken();

  if (token === null) {
    console.log('No token found.');
    return [] as Order[];
  }

  if (locations.length === 0) {
    return [] as Order[];
  }

  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);

  const start = dateToUtc(date, locations[0].timezone);
  const end = dateToUtc(date, locations[0].timezone);
  end.setDate(end.getDate() + 1);

  try {
    // Create the query params.
    const queryParams = new URLSearchParams();
    queryParams.append('startDate', start.toISOString());
    queryParams.append('endDate', end.toISOString());

    for (let i = 0; i < locations.length; i++) {
      queryParams.append('locationId', locations[i].id);
    }

    // Make the request.
    const response = await fetch(
      `${
        import.meta.env.VITE_API_URL
      }/franchises/${franchiseId}/orders?${queryParams.toString()}`,
      {
        method: 'GET',
        headers: {
          Authorization: token,
        },
      }
    );

    if (!response.ok) {
      return [];
    }

    const data = await response.json();
    return data as Order[];
  } catch (error) {
    console.error(error);
  }

  return [];
}

/**
 * @description Get the order totals for a week at a location.
 */
export async function getOrderTotals(
  franchiseId: string,
  startDate: Date,
  endDate: Date,
  locations: string[],
  timezone: string
): Promise<
  Array<{
    date: string;
    total: number;
  }>
> {
  const token = await getUserToken();

  startDate.setHours(0);
  startDate.setMinutes(0);
  startDate.setSeconds(0);

  endDate.setHours(0);
  endDate.setMinutes(0);
  endDate.setSeconds(0);

  const start = dateToUtc(startDate, timezone);
  const end = dateToUtc(endDate, timezone);

  if (token === null) {
    console.log('No token found.');
    return [];
  }

  try {
    // Create the query params.
    const queryParams = new URLSearchParams();
    queryParams.append('startDate', start.toISOString());
    queryParams.append('endDate', end.toISOString());

    for (let i = 0; i < locations.length; i++) {
      queryParams.append('locationId', locations[i]);
    }

    // Make the request.
    const response = await fetch(
      `${
        import.meta.env.VITE_API_URL
      }/franchises/${franchiseId}/orders/totals?${queryParams.toString()}`,
      {
        method: 'GET',
        headers: {
          Authorization: token,
        },
      }
    );

    if (!response.ok) {
      return [];
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }

  return [];
}

/**
 * @description Get the list of users for a given franchise
 */
export async function getFranchiseUsers(
  franchiseId: string
): Promise<
  {userData: Array<FranchiseUser>, franchiseLocations: Array<FranchiseLocation>, franchiseConfiguration: FranchiseConfiguration | null}
> {
  const token = await getUserToken();

  if (token === null) {
    console.log('No token found.');
    return {userData: [], franchiseLocations: [], franchiseConfiguration: null};
  }
 
  try {

    // Make the request.
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/franchises/${franchiseId}/users`,
      {
        method: 'GET',
        headers: {
          Authorization: token,
        },
      }
    );

    if (!response.ok) {
      return {userData: [], franchiseLocations: [], franchiseConfiguration: null};
    }

    const data = await response.json();
    return {
      userData: data["userData"], 
      franchiseLocations: data["franchiseLocations"],
      franchiseConfiguration: data["franchiseConfiguration"]
    }
  } catch (error) {
    console.error(error);
  }

  return {userData: [], franchiseLocations: [], franchiseConfiguration: null};
}

/**
 * @description Get the list of users for a given franchise
 */
export async function updateFranchiseConfiguration(
  franchiseId: String,
  franchiseConfiguration: FranchiseConfiguration
): Promise<boolean> {
  const token = await getUserToken();

  if (token === null) {
    console.log('No token found.');
    return false
  }
 
  try {

    // Make the request.
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/franchises/${franchiseId}/users`,
      {
        method: 'POST',
        headers: {
          Authorization: token,
        },
        body: JSON.stringify(franchiseConfiguration)
      }
    );

    if (!response.ok) {
      return false;
    }
    return true
  } catch (error) {
    console.error(error);
  }

  return false;
}

/**
 * @description Get the detections for a location.
 */
export async function getDetections(
  startDate: Date,
  endDate: Date,
  locations: string[],
  isDriveThru: boolean,
  franchiseId?: string
): Promise<Detection[]> {
  const token = await getUserToken();

  if (token === null) {
    console.log('No token found.');
    return [] as Detection[];
  }

  try {
    // Create the query params.
    const queryParams = new URLSearchParams();
    queryParams.append(
      'startDate',
      `${startDate.getFullYear()}-${
        startDate.getMonth() + 1
      }-${startDate.getDate()}`
    );
    queryParams.append(
      'endDate',
      `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`
    );
    queryParams.append('isDriveThru', isDriveThru.toString());

    for (let i = 0; i < locations.length; i++) {
      queryParams.append('locationId', locations[i]);
    }

    if (franchiseId) {
      queryParams.append(
        'franchiseId',
        franchiseId.toString()
      );
    }

    // Make the request.
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/detections?${queryParams.toString()}`,
      {
        method: 'GET',
        headers: {
          Authorization: token,
        },
      }
    );

    if (!response.ok) {
      return [];
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }

  return [];
}

/**
 * @description Get the averages for a location.
 */
export async function getAverages(
  franchiseId: string,
  startDate: Date,
  endDate: Date,
  locations: string[],
  isDriveThru: boolean
): Promise<Average[]> {
  const token = await getUserToken();

  if (token === null) {
    console.log('No token found.');
    return [] as Average[];
  }

  try {
    // Create the query params.
    const queryParams = new URLSearchParams();
    queryParams.append('startDate', startDate.toISOString());
    queryParams.append('endDate', endDate.toISOString());
    queryParams.append('isDriveThru', isDriveThru.toString());

    for (let i = 0; i < locations.length; i++) {
      queryParams.append('locationId', locations[i]);
    }

    // Make the request.
    const response = await fetch(
      `${
        import.meta.env.VITE_API_URL
      }/franchises/${franchiseId}/averages/daily?${queryParams.toString()}`,
      {
        method: 'GET',
        headers: {
          Authorization: token,
        },
      }
    );

    if (!response.ok) {
      return [];
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }

  return [];
}

/**
 * @description Get the ranks for a location.
 */
export async function getRanks(
  franchiseId: string,
  startDate: Date,
  endDate: Date,
  locations: string[],
  isDriveThru: boolean
): Promise<Rank[]> {
  const token = await getUserToken();

  if (token === null) {
    console.log('No token found.');
    return [] as Rank[];
  }

  try {
    // Create the query params.
    const queryParams = new URLSearchParams();
    queryParams.append(
      'startDate',
      `${startDate.getFullYear()}-${
        startDate.getMonth() + 1
      }-${startDate.getDate()}`
    );
    queryParams.append(
      'endDate',
      `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`
    );
    queryParams.append('isDriveThru', isDriveThru.toString());

    for (let i = 0; i < locations.length; i++) {
      queryParams.append('locationId', locations[i]);
    }

    // Make the request.
    const response = await fetch(
      `${
        import.meta.env.VITE_API_URL
      }/franchises/${franchiseId}/ranks?${queryParams.toString()}`,
      {
        method: 'GET',
        headers: {
          Authorization: token,
        },
      }
    );

    if (!response.ok) {
      return [];
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }

  return [];
}

/**
 * @description Get the regions stats for a location.
 */
export async function getRegionsStats(
  franchiseId: string,
  startDate: Date,
  endDate: Date,
  locations: string[],
  isDriveThru: boolean
): Promise<RegionsStats[]> {
  const token = await getUserToken();

  if (token === null) {
    console.log('No token found.');
    return [] as RegionsStats[];
  }

  try {
    // Create the query params.
    const queryParams = new URLSearchParams();

    queryParams.append(
      'startDate',
      startDate.toISOString().slice(0, 10)
    );
    queryParams.append(
      'endDate',
      endDate.toISOString().slice(0, 10)
    );
    queryParams.append('isDriveThru', isDriveThru.toString());

    for (let i = 0; i < locations.length; i++) {
      queryParams.append('locationId', locations[i]);
    }

    // Make the request.
    const response = await fetch(
      `${
        import.meta.env.VITE_API_URL
      }/v2/franchises/${franchiseId}/regions?${queryParams.toString()}`,
      {
        method: 'GET',
        headers: {
          Authorization: token,
        },
      }
    );

    if (!response.ok) {
      return [];
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }

  return [];
}


/**
 * @description Get the regions stats for a location.
 */
export async function getStackDataByLocationId(
  franchiseId: string,
  startDate: Date,
  endDate: Date,
  locations: string[],
  options: { signal?: AbortSignal } = {}
): Promise<Stack | undefined> {
  const token = await getUserToken();
  const results: Stack | undefined = undefined;

  if (token === null) {
    console.log('No token found.');
    return results; // Return undefined if no token
  }

  try {
    // Create the query params.
    const queryParams = new URLSearchParams();
    
    queryParams.append('start_date', startDate.toISOString().slice(0, 10));
    queryParams.append('end_date', endDate.toISOString().slice(0, 10));
    queryParams.append('timezone', 'America/Los_Angeles');
    queryParams.set('location_ids', locations.join(','));

    // Make the request.
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/franchises/${franchiseId}/stacks-driveoffs?${queryParams.toString()}`,
      {
        method: 'GET',
        headers: {
          Authorization: token,
        },
        signal: options.signal, // Pass the abort signal here
      }
    );

    if (!response.ok) {
      console.error(`Failed to fetch: ${response.status} ${response.statusText}`);
      return results; // Return undefined if response is not ok
    }

    const data = await response.json();
    return data as Stack;
  } catch (error) {
    if (error instanceof Error) { // Type guard to check if it's an Error
      if (error.name === 'AbortError') {
        console.log('Fetch request was aborted');
      } else {
        console.error('Error fetching stack data:', error.message);
      }
    } else {
      console.error('Unexpected error:', error); // Handle unknown types
    }
    return results; // Return undefined on error
  }
}

/**
 * @description Get day part data for a location.
 */
export async function getDayPartsData(
  franchiseId: string,
  startDate: Date,
  endDate: Date,
  locations: string[],
  isDriveThru: boolean
): Promise<DayPartData[]> {
  const token = await getUserToken();

  if (token === null) {
    console.log('No token found.');
    return [] as DayPartData[];
  }

  try {
    // Create the query params.
    const queryParams = new URLSearchParams();

    queryParams.append(
      'startDate',
      `${startDate.getFullYear()}-${
        startDate.getMonth() + 1
      }-${startDate.getDate()}`
    );
    queryParams.append(
      'endDate',
      `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`
    );
    queryParams.append('isDriveThru', isDriveThru.toString());

    for (let i = 0; i < locations.length; i++) {
      queryParams.append('locationId', locations[i]);
    }

    // Make the request.
    const response = await fetch(
      `${
        import.meta.env.VITE_API_URL
      }/franchises/${franchiseId}/day-parts?${queryParams.toString()}`,
      {
        method: 'GET',
        headers: {
          Authorization: token,
        },
      }
    );

    if (!response.ok) {
      return [];
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }

  return [];
}

/**
 * @description Get the cameras for a location.
 * @param location The location to get cameras for.
 * @returns The cameras for a location.
 */
export async function getLocationCameras(
  location: Location
): Promise<Camera[]> {
  const token = await getUserToken();

  if (token === null) {
    console.log('No token found.');
    return [] as Camera[];
  }

  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/franchises/${
        location.franchiseId
      }/locations/${location.id}/cameras`,
      {
        method: 'GET',
        headers: {
          Authorization: token,
        },
      }
    );

    if (!response.ok) {
      return [] as Camera[];
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }

  return [] as Camera[];
}

/**
 * @param franchiseId The franchise id of the location
 * @param locationId The location id to update.
 * @param displayName The display name to update.
 * @param dayParts The day parts to update.
 * @returns True if the location settings were updated successfully.
 */
export async function putLocationSettings(
  franchiseId: string,
  locationId: string,
  displayName: string,
  dayParts: DayPart[]
): Promise<boolean> {
  const token = await getUserToken();

  if (token === null) {
    console.log('No token found.');
    return false;
  }

  try {
    const response = await fetch(
      `${
        import.meta.env.VITE_API_URL
      }/franchises/${franchiseId}/locations/${locationId}/settings`,
      {
        method: 'PUT',
        headers: {
          Authorization: token,
        },
        body: JSON.stringify({
          displayName,
          dayParts,
        }),
      }
    );

    if (!response.ok) {
      return false;
    }

    return true;
  } catch (error) {
    console.error(error);
  }

  return false;
}

/**
 *
 * @param notificationLocations - The notification locations to update.
 * @returns The updated user profile.
 */
export async function patchUserProfile(
  notificationLocations: string[]
): Promise<UserProfile> {
  const token = await getUserToken();

  if (token === null) {
    console.log('No token found.');
    return {
      locations: [],
      franchiseRoles: new Map<string, Roles>(),
      notificationLocations: [],
      franchises: []
    };
  }

  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/user/profile`,
      {
        method: 'PATCH',
        headers: {
          Authorization: token,
        },
        body: JSON.stringify({
          notificationLocations,
        }),
      }
    );

    if (!response.ok) {
      return {
        locations: [],
        franchiseRoles: new Map<string, Roles>(),
        notificationLocations: [],
        franchises: []
      };
    }

    const data = await response.json();
    return data as UserProfile;
  } catch (error) {
    console.error(error);
  }

  return {
    locations: [],
    franchiseRoles: new Map<string, Roles>(),
    notificationLocations: [],
    franchises: []
  };
}

/**
 *
 * @param notificationLocations - The notification locations to update.
 * @returns The updated user profile.
 */
export async function postUserProfile(
  newUser: FranchiseUser
): Promise<boolean> {
  const token = await getUserToken();

  if (token === null) {
    console.log('No token found.');
    return false;
  }

  try {
    const franchiseId = newUser.franchiseId;
    const role = newUser.userRole;
    const email = newUser.email;
    const locations = newUser.locationIds.map((locId) => { return {locationId: locId, franchiseId: franchiseId}})

    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/user/profile`,
      {
        method: 'POST',
        headers: {
          Authorization: token,
        },
        body: JSON.stringify({
          locations,
          franchiseId,
          role,
          email
        }),
      }
    );

    if (!response.ok) {
      return false;
    }

    return true
  } catch (error) {
    console.error(error);
  }

  return false;
}

/**
 *
 * @param notificationLocations - The notification locations to update.
 * @returns The updated user profile.
 */
export async function updateUserProfile(
  user: FranchiseUser
): Promise<boolean> {
  const token = await getUserToken();

  if (token === null) {
    console.log('No token found.');
    return false;
  }

  try {
    const userId = user.userId;
    const franchiseId = user.franchiseId;
    const role = user.userRole;
    const email = user.email;
    const locations = user.locationIds.map((locId) => { return {locationId: locId, franchiseId: franchiseId}})

    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/user/profile`,
      {
        method: 'PUT',
        headers: {
          Authorization: token,
        },
        body: JSON.stringify({
          locations,
          userId,
          franchiseId,
          role,
          email
        }),
      }
    );

    if (!response.ok) {
      return false;
    }

    return true
  } catch (error) {
    console.error(error);
  }

  return false;
}

/**
 *
 * @param notificationLocations - The notification locations to update.
 * @returns The updated user profile.
 */
export async function deleteUserProfile(
  user: FranchiseUser
): Promise<boolean> {
  const token = await getUserToken();

  if (token === null) {
    console.log('No token found.');
    return false;
  }

  try {
    const franchiseId = user.franchiseId;
    const userId = user.userId;

    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/user/profile`,
      {
        method: 'DELETE',
        headers: {
          Authorization: token,
        },
        body: JSON.stringify({
          userId,
          franchiseId
        }),
      }
    );

    if (!response.ok) {
      return false;
    }

    return true
  } catch (error) {
    console.error(error);
  }

  return false;
}
