/**
 * Analytics Page
 * This page is intended to empower the customer to uncover
 * insights and trends in their data, over longer periods of time.
 */
import { useState, useEffect } from 'react';
import { useData } from '../../hooks/useData';
import { getStackDataByLocationId } from '../../utils/api';
import DropdownFranchise from '../../components/dropdowns/DropdownFranchise';
import Datepicker from '../../components/time/Datepicker';
import PageLayout from '../../components/ui/PageLayout';
import StackGraph from '@/components/graphs/analytics/StackGraph';
import { Stack } from '@/types/Stack';
import DropdownStacksAggregate from '@/components/dropdowns/DropdownStackAggregate';
import DropdownLocationMultiple from '@/components/dropdowns/DropdownLocationMultiple';
import StackTable from '@/components/tables/StackTable';
;
import DriveOffTable from '@/components/tables/DriveOffTable';
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from '@/components/ui/carousel';
import { PANDAFRANCHISE } from '@/constants';

export default function StacksContainer(): JSX.Element {
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);
  yesterday.setHours(0)
  
  const isBeta  = ((window.location.hostname.includes('beta')) || window.location.hostname.includes('localhost'))
  const [datesSet, setDatesSet] = useState(false);
  const [franchiseSet, setFranchiseSet] = useState(false);


  const [stackData, setStackData] = useState<Stack>();
  const [abortController, setAbortController] = useState<AbortController | null>(null);
  const {
    selectedFranchise,
    selectedLocations,
    selectedDates,
    setSelectedDates,
    handleFranchiseChange,
    setSelectedStackDriveOffFilter,
  } = useData();

  // Using effect chain to ensure that these effects happen in order
  useEffect(() => {
    
    setSelectedDates([yesterday, yesterday]);
    setDatesSet(true)
  }, []);

  useEffect(() => {
    if ((datesSet === true) || (selectedDates[0] == today)){
      return
    }
    handleFranchiseChange(PANDAFRANCHISE)
    setFranchiseSet(true)
  }, [selectedDates])

  useEffect(() => {
    if (franchiseSet === true){
      return
    }
    setSelectedStackDriveOffFilter("30min");

  }, [selectedFranchise])

  // Get day parts data when toggled or date or location or franchise changes.
  useEffect(() => {

    // skipping the first invocation if the date and franchise hasn't been set yet
    if (!(datesSet && franchiseSet)){
      return
    }
    if (abortController) {
      abortController.abort();
    }

    const newAbortController = new AbortController();
    setAbortController(newAbortController);

    async function getData(): Promise<void> {
      if (selectedFranchise === null || selectedLocations.length === 0) {
        return;
      }

      try {
        const data = await getStackDataByLocationId(
          selectedFranchise.id,
          selectedDates[0],
          selectedDates[1],
          selectedLocations.map((location) => location.id),
          { signal: newAbortController.signal } // pass the abort signal
        );
        setStackData(data);
      } catch (error) {
        if (error instanceof Error) { // Type guard to check if it's an Error
          if (error.name === 'AbortError') {
            console.log('Fetch request was aborted');
          } else {
            console.error('Error fetching stack data:', error.message);
          }
        } else {
          console.error('Unexpected error:', error); // Handle unknown types
        }
      }
    }
    getData().catch((error) => {
      console.error(error);
    });
  }, [selectedDates, selectedLocations, selectedFranchise]);

  // Handle date change
  function handleDateChange(dates: Date[]): void {
    setSelectedDates(dates);
  }

  return (
    <PageLayout>
      <div data-cy="stacks">
        <div className="sm:flex sm:items-centerm flex-col gap-4">
          {/* Selectors */}
          <div className="sm:flex sm:justify-between sm:items-center mb-8 gap-4">
            <DropdownFranchise />
            <DropdownLocationMultiple />
            <DropdownStacksAggregate />
            <Datepicker
              initDates={[yesterday, yesterday]}
              handleDateChange={handleDateChange}
            />
            
          </div>
           {/* Stacks */}
           <StackGraph stackData={stackData} />
           
          {/* Conditionally render the Carousel based on the URL host (TODO: need better way of doing this so that we separate beta from prod) */}
          {isBeta && (
          <Carousel className="">
            <CarouselContent>
              <CarouselItem>
                <StackTable stackData={stackData?.stackData} />
              </CarouselItem>
              <CarouselItem>
                <DriveOffTable driveOffsData={stackData?.driveOffData} />
              </CarouselItem>
            </CarouselContent>
            <CarouselPrevious />
            <CarouselNext />
          </Carousel>
          )}
          
        </div>
      </div>
    </PageLayout>
  );

}